<template>
    <div class="tab_wrapper">
        <ul class="d_flex">
            <li
                v-for="(el, idx) in tabList"
                :key="idx"
                :class="[ currentTab === el.value ? 'body-semibold' : 'body-regular unchoose', { active: currentTab === el.value,}]"
                :style="{ color: '#0D0C0C', width: widthHandler + '%' }"
                @click="tabUpdater(el.value)"
            >
                {{ el.name }}
            </li>
            <div
                class="orange_line"
                :style="{ left: lineHandler + '%', width: widthHandler + '%' }"
            ></div>
        </ul>
        <div class="border_bottom"></div>
    </div>
</template>

<script>
export default {
    name: 'Tab',
    props: {
        tabList: {
            type: Array,
            required: true,
        },
        currentTab: {
            type: String,
            required: true,
        },
    },
    computed: {
        lineHandler() {
            const idx = this.tabList.findIndex((item) => {
                return item.value === this.currentTab;
            });
            return idx * this.widthHandler;
        },
        widthHandler() {
            return 100 / this.tabList.length;
        },
    },
    mounted() {
    },
    methods: {
        tabUpdater(value) {
            this.$emit('update', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.unchoose {
    color: #909090 !important;
}
.tab_wrapper {
    height: 42px;
    align-items: top;
    // border-bottom: solid 1px #909090;
    ul {
        width: calc(100%);
        padding: 10px 0 12px;
        position: relative;
        margin: 0 auto;
        list-style: none;
        border-bottom: solid 1px #909090;
        li {
            flex-grow: 2;
            line-height: 20px;
            &.active {
                color: #ff9f4b !important;
            }
        }
        .orange_line {
            position: absolute;
            z-index: 1;
            bottom: -1px;
            height: 2px;
            background-color: #FF9F4B;
            transition: all 0.3s;
        }
    }
}
</style>
